export const language = {
    CN: '中文', // 中文
    EN: 'English', // 英文 English
    PT: 'Portugal', // 葡萄牙 Portugal
    anjun: '本查询系统隶属安骏物流旗下网站', // 安骏所属
    trackQuery: '轨迹查询', // 轨迹查询
    submit: '马上查询', // 马上查询
    returnToHome: '返回首页', // 返回首页
    register: '注册账号', // 注册
    placeholder: '请输入物流单号码查询轨迹，支持多个单号换行查询', // 轨迹查询输入框placeholder
    placeholderMo: '请输入物流单号码查询轨迹', // 轨迹查询输入框placeholder
    allTracks: '全部', // 全部轨迹
    queryNotFound: '查询不到', // 查询不到轨迹
    duringTransportation: '运输途中', // 运输途中
    download: '到达待取', // 到达待取
    deliveryFailed: '投递失败', // 投递失败
    signedIn: '成功签收', // 成功签收
    customerService: '客服', // 客服
    forecast: '已预报', // 已预报
    airTakeOff: '航空起飞', // 航空起飞
    arrivalAtDestinationAirport: '到达目的国机场', // 到达目的国机场
    delivered: '已签收', // 已签收
    beCareful: '注意', // 注意
    upToQueries: '每次只查询前50条',
    pleaseEnterEnquiryNumber: '请输入查询号码'
};
